import { graphql } from 'gatsby'
import PageBlogPosts from '../components/PageBlogPosts'

export default PageBlogPosts

export const query = graphql`query PageBlogPosts {
  site {
    siteMetadata {
      title
      description
    }
  }
  blogPosts: allMdx(filter: {fields: {sourceName: {eq: "blog-posts"}}}) {
    edges {
      node {
        frontmatter {
          id
          post_title
        }
      }
    }
  }
  articles: allMdx(filter: {fields: {sourceName: {eq: "blog-posts"}}}) {
    edges {
      node {
        frontmatter {
          id
          tags_for_display
          post_title
          date
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
